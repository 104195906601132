<template>
  <div
    class="usdt container van-tab__panel"
    :style="{ backgroundImage: 'url(img/pro-bg.png)' }"
  >
    <!-- <h3>昵称:{{ userInfo.info.usernick }}</h3> -->
    <div class="tophead shadow default border-r5 con mt-10">
      <van-icon name="paid" style="margin-left: 6px" />
      <span class="fz14"
        >{{ $t("public.text1") }}:<strong class="fz16 green">{{
          userInfo.info.balance
        }}</strong></span
      >
    </div>
    <van-loading v-if="loading" />
    <van-tabs
      v-model:active="tabActive"
      type="card"
      class="noshadow txt-center mt-10"
      v-if="!loading"
    >
      <van-tab
        :title="item.name"
        v-for="item in dataList"
        :key="item.id"
        animated
      >
        <div
          class="box con mt-10 border-r5 txt-left"
          v-for="(itemChild, index) in item.room"
          :key="index"
          @click="
            goPage(
              item,
              itemChild.room_name,
              item.name,
              itemChild.low_limit,
              itemChild.id
            )
          "
          :class="'bg' + index"
        >
          <h3 style="margin-top: 0px">
            {{ itemChild.room_name }}
            <p>({{ itemChild.room_name }})</p>
            <p style="margin-top: 30px">{{ $t("room.text1") }} : 1</p>
          </h3>
          <van-icon name="arrow" style="font-size: 24px" />
        </div>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { getRooms } from "@/api/lottery";
import { useRouter } from "vue-router";
import { Toast } from "vant";
import store from "@/store";
const { userInfo } = store.getters;
const router = useRouter();
const tabActive = ref(0);
const loading = ref(true);
const dataList = ref([]);
import { useI18n } from "vue-i18n";
const { t } = useI18n();
const goPage = (item, name, room_name, itemChild, id) => {
  if (1 > Number(userInfo.info.balance)) {
    Toast.fail(t("public.Insufficient") + "1");
    return true;
  }
  router.push({
    path: "/pages/play",
    query: {
      id: item.id,
      ident: item.ident,
      type: name,
      name: room_name,
      room_id: id,
    },
  });
};
const getData = () => {
  getRooms()
    .then((res) => {
      dataList.value = res.data;
      console.log(res);
      loading.value = false;
    })
    .finally(() => {
      loading.value = false;
    });
};
getData();
</script>

<style lang="less" scoped>
.usdt {
  background-repeat: no-repeat;
  background-size: 80% auto;
  background-position: 0 -50px;
  color: #ddd;
  min-height: 300px;

  .tophead,
  .box {
    display: flex;
    justify-content: left;
    /* 水平居中 */
    align-items: center;
    justify-content: space-between;
    font-size: 18px;

    i:first-child {
      font-size: 40px;
      margin-right: 20px;
    }

    span {
      width: 80%;
    }
  }

  .box {
    background-repeat: no-repeat;
    background-size: 100%;
    width: 90%;
    margin: 5px auto;
    -webkit-border-radius: 18px;
    border-radius: 18px;
    height: 125px;
    &.bg0 {
      background-image: url("~@/assets/zip.png");
      background-position: 0% 0%;
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }

    &.bg1 {
      background-image: url("~@/assets/zip1.png");
      background-position: 0% 0%;
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }

    &.bg2 {
      background-image: url("~@/assets/zip2.png");
      background-position: 0% 0%;
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }

    h3 {
      margin: 0;

      p {
        margin: 0;
        font-size: 14px;
        font-weight: 400;
        line-height: 25px;
      }
    }
  }
  ::v-deep .van-tab--active {
    background-color: rgb(0, 122, 255) !important;
    color: #fff !important;
  }
}
</style>
